
<template>
    <div class="message counteroffer" :class="{message_bottom:!isTrue}" style="height: 100%;overflow-y: auto;">
        <div class="top" style="height:40px">
            <van-sticky :offset-top="0">
                <div class="public_header">
                    <router-link to>
                        <van-icon @click="router_link" name="arrow-left" color="#fff" />
                    </router-link>
                    <span>我的还价</span>
                    <span class="btn" v-if="isTrue" @click="transmit(isTrue)">编辑</span>
                    <span class="btn" v-else  @click="transmit(isTrue)">取消</span>
                    <router-link to="/message">
                        <van-icon class="icon_search" name="envelop-o" :dot='true' color="#fff" />
                    </router-link>
                </div>
            </van-sticky>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height: calc(100% - 40px);overflow-y:auto;">
            <div class="offer_w">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    offset="50"
                    finished-text="没有更多了"
                    :error.sync="error"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                >
                    <van-cell v-for="item in list" :key="item.id" @click="toDetailed($event, item)">
                        <div class="index_list offer_list">
                            <div class="offer_list_t">
                                <span class="index_list_l">
                                    <van-checkbox v-if="!isTrue" v-model="item.checked"></van-checkbox>
                                    <img class="profilePhoto" :src="item.imgSrc">
                                    <span class="index_list_c">
                                        <div class="index_list_c_t">{{item.channel_name}}\{{item.job}}\{{item.lv}}级</div>
                                        <div class="index_list_c_b">
                                            <span class="index_list_c_b_t" :class="{shenhezhong : item.status == 1,gongshiqi : item.status == 8,chushouqi : item.status == 2,xiajia : item.status == 7, shanchu: item.status == 4 || item.status == 5, yishouchu: item.status == 3}">{{item.status_name}}</span>
                                            <span class="index_list_c_b_b">{{item.surplus_time}}</span>
                                        </div>
                                    </span>
                                </span>
                                <span class="index_list_r">
                                    <div class="index_list_r_t">{{item.server_name}}</div>
                                    <div class="index_list_r_b">
                                        <span>￥{{item.oldprice}}</span>
                                        <span>￥{{item.price}}</span>
                                    </div>
                                    <div class="index_list_r_bn">
                                        <van-button plain round type="default" size="mini" :class="{huanjia: item.state == 1, refuse: item.state == 2, agree: item.state == 3}">{{item.stateName}}</van-button>
                                    </div>
                                </span>
                            </div>
                            <div class="offer_list_b">
                                <span class="logbtn">还价记录</span>
                                <span class="pay_btn" :class="{isRefused: item.state == 2}" v-if="item.state == 1 || item.state == 2 || item.state == 3">{{item.state == 2 ? '再次还价' : '去支付'}}</span>
                            </div>
                        </div>
                    </van-cell>
                </van-list>
            </div>
        </van-pull-refresh>
        <div class="fixd_btnlist" v-if="!isTrue">
            <van-button class="readbtn" round type="info" @click="allClick()" size="large" color="linear-gradient(to right, #FF704D, #F73727)" native-type="submit">全部选择</van-button>
            <van-button round type="info" @click="delBtn()" color="linear-gradient(to right, #FF704D, #F73727)" size="large"  native-type="submit" :disabled="disabled">删除</van-button>
        </div>
        <van-overlay :show="counterShow" class="counterw" @click="counterShow=false;">
            <div class="wrap">
                <div class="block_w" @click.stop>
                    <h3>还价记录（{{logList.length}}）</h3>
                    <van-icon name="cross" @click="counterShow=false;" />
                    <div class="log_w">
                        <ul v-if="logList.length">
                            <li v-for="item in logList" :key="item.id">
                                <div>
                                    <span>¥{{item.price}}</span>
                                    <span :class="{refuse: item.state == 2, agree: item.state == 3}">（{{item.stateName}}）</span>
                                </div>
                                <span>{{item.time}}</span>
                            </li>
                        </ul>
                        <van-empty description="暂无记录" v-else />
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>
<script>
export default {
    name: 'Counteroffer',
    data() {
        return {
            isLoading: false,
            isTrue: true,
            active: 0,
            finished: true,
            error: false,
            loading: false,
            checked: false,
            list:[
                {
                    imgSrc: require('../../assets/images/photos/zs-nan@2x.png'),
                    channel_name: '仙境传奇',
                    job: '战士',
                    lv: 150,
                    status: 2,
                    status_name: '上架中',
                    surplus_time: '剩余1天2小时',
                    server_name: '巅峰-巅峰001',
                    price: 3436,
                    oldprice: 2000,
                    state: 1,
                    stateName: '卖家出价',
                    goods_id: 10001123,
                    checked: false,
                    id: 1,
                },{
                    imgSrc: require('../../assets/images/photos/zs-nan@2x.png'),
                    channel_name: '仙境传奇',
                    job: '战士',
                    lv: 150,
                    status: 3,
                    status_name: '上架中',
                    surplus_time: '剩余1天2小时',
                    server_name: '巅峰-巅峰001',
                    price: 3436,
                    oldprice: 2000,
                    state: 2,
                    stateName: '卖家已拒绝',
                    goods_id: 10001123,
                    checked: false,
                    id: 2,
                },{
                    imgSrc: require('../../assets/images/photos/zs-nan@2x.png'),
                    channel_name: '仙境传奇',
                    job: '战士',
                    lv: 150,
                    status: 4,
                    status_name: '上架中',
                    surplus_time: '剩余1天2小时',
                    server_name: '巅峰-巅峰001',
                    price: 3436,
                    oldprice: 2000,
                    state: 3,
                    stateName: '卖家已同意',
                    goods_id: 10001123,
                    checked: false,
                    id: 3,
                },{
                    imgSrc: require('../../assets/images/photos/zs-nan@2x.png'),
                    channel_name: '仙境传奇',
                    job: '战士',
                    lv: 150,
                    status: 5,
                    status_name: '上架中',
                    surplus_time: '剩余1天2小时',
                    server_name: '巅峰-巅峰001',
                    price: 3436,
                    oldprice: 2000,
                    state: 4,
                    stateName: '等待卖家处理',
                    goods_id: 10001123,
                    checked: false,
                    id: 4,
                },{
                    imgSrc: require('../../assets/images/photos/zs-nan@2x.png'),
                    channel_name: '仙境传奇',
                    job: '战士',
                    lv: 150,
                    status: 1,
                    status_name: '上架中',
                    surplus_time: '剩余1天2小时',
                    server_name: '巅峰-巅峰001',
                    price: 3436,
                    oldprice: 2000,
                    state: 5,
                    stateName: '已失效',
                    goods_id: 10001123,
                    checked: false,
                    id: 5,
                }
            ],
            page:1,
            deleteListId:'',
            updateList:[],
            counterShow: false,
            logList: [
                {
                    price: 5000,
                    stateName: '已同意',
                    time: '2021-12-06 15:58:20',
                    state: 3
                },{
                    price: 5200,
                    stateName: '已拒绝',
                    time: '2021-12-06 15:58:20',
                    state: 2
                }
            ]
        }
    },
    computed: {
        disabled() {
            let disArr =  this.list.filter(item => {
                return item.checked == true
            });
            return disArr.length < 1
        }
    },
    created(){
        // console.log(window.history)
    },
    methods:{
        // 下拉刷新
        onRefresh(){
            // this.error = false;
            // this.loading = true;
            // this.list = [];
            // this.page = 1;
            // this.finished = false;
            // this.onLoad();
        },
        // 返回按钮
        router_link(){
            if(window.history.length <= 2){
                this.$router.push({
                    name: 'Home'
                });
            }else{
                this.$router.back(-1);
            }
        },
        // 点击删除
        delBtn(){
            this.deleteListId = '';
            this.updateList = [];
            this.list.forEach((item)=>{
                if(item.checked){
                    this.deleteListId += item.id + ',';
                }else{
                    this.updateList.push(item);
                }
            });
            this.deleteListId = this.deleteListId.substr(0, this.deleteListId.length-1);

            this.list = this.updateList;
            this.isTrue = true;
            this.$toast({
                message: '删除成功',
                duration: 1500
            });

            // let data = {id:this.deleteListId}
            // this.$api.message.delete(data).then((res)=>{
            //     if(res.code==200){
            //         this.list = this.updateList;
            //         this.isTrue = true;
            //         this.$toast({
            //             message: '删除成功',
            //             duration: 1500
            //         });
            //     }
            // });
        },
        // 点击全部选择
        allClick(){
            this.list.forEach(item => {
                item.checked = true;
            });

            // this.$api.message.allRead().then((res)=>{
            //     if(res.code==200){
            //         this.isTrue = true;
            //         this.list.forEach((item,index)=>{
            //             this.list[index].is_read = 1;
            //         })
            //     }
            // })
        },
        // 跳转到详情页
        toDetailed(e, item){
            //console.log(e, item);
            if (this.isTrue) {
                if (e.target.className.includes('logbtn')) {
                    //还价记录按钮点击
                    //console.log('还价记录');
                    this.counterShow = true;
                } else if (e.target.className.includes('isRefused')) {
                    //卖家已拒绝，可再次还价,跳转至还价页
                    //console.log('拒绝');
                    this.$router.push({
                        path: '/dicker',
                        query: {
                            goods_id: item.goods_id
                        }
                    });
                } else if (e.target.className.includes('pay_btn')) {
                    //卖家已同意或者卖家出价,跳转至商品购买页
                    //console.log('同意');
                    this.$router.push({
                        path: '/paymentpage',
                        query: {
                            goods_id: item.goods_id
                        }
                    });
                } else {
                    //点击订单可跳转至商品详情
                    //console.log('普通');
                    this.$router.push({
                        path: '/characterdetails',
                        query: {
                            goods_id: item.goods_id,
                            channel_id: ''
                        }
                    });
                }
            }
        },
        // 点击编辑||取消
        transmit(type){
            this.isTrue = !type;
        },
        // 加载数据
        onLoad(){
            // this.$api.message.getList({page:this.page}).then((res)=>{
            //     this.isLoading = false
            //     if (res.code == 200) {
            //         if (res.data.length > 0) {
            //             res.data.forEach((item, index, arr)=>{
            //                 res.data[index].checked = false
            //             });
            //             this.list = this.list.concat(res.data);
            //             this.loading = false;
            //             ++this.page;
            //         }else{
            //             //加载状态结束
            //             this.loading = false;
            //             //数据全部加载完成
            //             this.finished = true;
            //         }
            //     }else{
            //         //加载状态结束
            //         this.loading = false;
            //         //错误提示
            //         this.error = true;
            //     }
                
            // })
        }
    }
}
</script>
<style scoped>
.counteroffer{
    background-color: #e0e0e0;
}
.btn{
    position: absolute;
    top: 0;
    right: 12%;
}
.message_bottom{
    padding-bottom: 3.6875rem;
}
.offer_w{
    /* background-color: #e0e0e0; */
    height: 100%;
}
.offer_w .van-cell{
    background: none;
    padding: 1% 2%;
}
.offer_w .van-cell::after{
    display: none;
}
.offer_list{
    display: block;
    background: #fff;
    padding: 2% 3%;
    border-radius: 5px;
}
.offer_list_t,
.offer_list_b{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.offer_list_t{
    border-bottom: 1px dashed #BFBFBF;
    padding-bottom: .5rem;
}
.offer_list_b{
    padding-top: .2rem;
}
.offer_list_t .profilePhoto{
    margin-right: .5rem;
}
.offer_list_t .index_list_l .van-checkbox{
    margin-right: .5rem;
}
.offer_list_t .index_list_c_b_b{
    display: block;
}
.offer_list_t .index_list_r_b span:first-child{
    color: #313131;
    text-decoration: line-through;
    font-size: 12px;
    padding-right: .25rem;
}
.offer_list_t .index_list_c_t,
.offer_list_t .index_list_r_t{
    margin-bottom: .15625rem;
}
.offer_list_t .index_list_c_b_t{
    padding: 0 .25rem;
    margin: .2rem 0;
    display: inline-block;
}
.offer_list_t .index_list_r_bn{
    line-height: 12px;
}
.offer_list_t .index_list_r_bn button{
    padding: 0 .625rem;
    color: #909090;
    border-color: #909090;
    height: 22px;
}
.offer_list_t .index_list_r_bn .huanjia{
    color: #FF4E00;
    border-color: #FF4E00;
}
.offer_list_t .index_list_r_bn .refuse{
    color: #FF0000;
    border-color: #FF0000;
}
.offer_list_t .index_list_r_bn .agree{
    color: #04A400;
    border-color: #04A400;
}
.offer_list_b span:first-child{
    color: #808080;
}
.offer_list_b .pay_btn{
    position: relative;
    padding-right: 3%;
    color: #04A400;
}
.offer_list_b .pay_btn::after{
    content: '';
    position: absolute;
    top: 50%;
    right: 1px;
    width: 6px;
    height: 6px;
    border-top: 1px solid #04A400;
    border-right: 1px solid #04A400;
    transform: translate(0, -50%) rotate(45deg);
}
.offer_list_b .isRefused,
.offer_list_b .isRefused::after{
    color: #FF0000;
    border-color: #FF0000;
}
.fixd_btnlist{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3.6875rem;
    background: #fff;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #dadada;
    align-items: center;
}
.fixd_btnlist .delbtn{
    background: #828282;
    border-color: #828282;
}
.fixd_btnlist button{
    width: 40%;
    height: 2.34375rem;
}
.counterw .wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.counterw .block_w{
    width: 84%;
    height: 18.375rem;
    background-color: #efefef;
    border-radius: .5625rem;
    padding: .625rem 0;
    position: relative;
}
.counterw .block_w h3{
    font-size: .9375rem;
    line-height: 2.5em;
    border-bottom: 2px dotted #888;
    color: #313131;
    font-weight: normal;
}
.counterw .block_w i{
    position: absolute;
    top: .375rem;
    right: .375rem;
    font-size: 1.125rem;
}
.counterw .log_w ul{
    padding: .625rem .9375rem;
}
.counterw .log_w ul li{
    display: flex;
    justify-content: space-between;
    font-size: .8125rem;
    padding: .40625rem 0 .40625rem .9375rem;
    color: #808080;
    border-left: 2px dotted #888;
    margin-bottom: 2px;
    position: relative;
}
.counterw .log_w ul li::after{
    content: '';
    position: absolute;
    left: -0.3125rem;
    top: 50%;
    width: .375rem;
    height: .375rem;
    border-radius: 50%;
    border: 1px solid #888;
    transform: translate(0, -50%);
}
.counterw .log_w ul li div span:first-child{
    color: #313131;
}
.counterw .log_w ul li span.refuse{
    color: #EF4034;
}
.counterw .log_w ul li span.agree{
    color: #04A400;
}
</style>